import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { createClient } from '@supabase/supabase-js';

const supabase = createClient(
  'https://lllqncjwqcbrygqujdin.supabase.co',
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImxsbHFuY2p3cWNicnlncXVqZGluIiwicm9sZSI6ImFub24iLCJpYXQiOjE3Mzg3MjAxNzMsImV4cCI6MjA1NDI5NjE3M30.Un4XIFBnq1rOrSn484onaCHV0a_mTUYKRb-SVbhKXJQ'
);

const AdminHeader = () => {
  const navigate = useNavigate();

  const handleDisconnect = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      navigate('/admin/login');
    } catch (error) {
      console.error('Error logging out:', error.message);
    }
  };

  return (
    <header className="bg-white shadow">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
        <div className="flex justify-between items-center">
          <div className="flex space-x-4">
            <Link to="/admin" className="text-gray-700 hover:text-gray-900">
              Dashboard
            </Link>
            <Link to="/admin/news/add" className="text-gray-700 hover:text-gray-900">
              Add News
            </Link>
            <Link to="/admin/news/manage" className="text-gray-700 hover:text-gray-900">
              Manage Posts
            </Link>
          </div>
          <div className="flex items-center space-x-4">
            <button
              onClick={handleDisconnect}
              className="px-4 py-2 text-red-600 hover:text-red-800"
            >
              Disconnect
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default AdminHeader;