import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import Header from './Header';
import { useNavigate } from 'react-router-dom';

const supabase = createClient(
  'https://lllqncjwqcbrygqujdin.supabase.co',
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImxsbHFuY2p3cWNicnlncXVqZGluIiwicm9sZSI6ImFub24iLCJpYXQiOjE3Mzg3MjAxNzMsImV4cCI6MjA1NDI5NjE3M30.Un4XIFBnq1rOrSn484onaCHV0a_mTUYKRb-SVbhKXJQ'
);

const ManagePosts = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchPosts = async () => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        navigate('/admin/login');
        return;
      }

      const { data, error } = await supabase
        .from('news')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      setPosts(data);
    } catch (error) {
      console.error('Error fetching posts:', error);
      setMessage('Error loading posts');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (postId, imageUrl) => {
    if (!window.confirm('Are you sure you want to delete this post?')) {
      return;
    }

    setLoading(true);
    try {
      // Delete the image from storage if it exists
      if (imageUrl) {
        const imagePath = imageUrl.split('/').pop();
        await supabase.storage
          .from('news-images')
          .remove([imagePath]);
      }

      // Delete the post
      const { error } = await supabase
        .from('news')
        .delete()
        .match({ id: postId });

      if (error) throw error;

      setPosts(posts.filter(post => post.id !== postId));
      setMessage('Post deleted successfully');
    } catch (error) {
      console.error('Error deleting post:', error);
      setMessage('Error deleting post');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <div className="flex-grow bg-gray-50 py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold mb-8">Manage Posts</h1>

          {message && (
            <div className={`mb-4 p-4 rounded ${
              message.startsWith('Error') ? 'bg-red-100 text-red-700' : 'bg-green-100 text-green-700'
            }`}>
              {message}
            </div>
          )}

          {loading ? (
            <div>Loading...</div>
          ) : (
            <div className="grid gap-6">
              {posts.map(post => (
                <div key={post.id} className="bg-white shadow rounded-lg p-6">
                  <div className="flex justify-between items-start">
                    <div>
                      <h2 className="text-xl font-semibold">{post.title}</h2>
                      <p className="text-gray-500 text-sm mt-1">
                        {new Date(post.created_at).toLocaleDateString()}
                      </p>
                      <p className="text-gray-600 mt-2">{post.category}</p>
                    </div>
                    <button
                      onClick={() => handleDelete(post.id, post.image_url)}
                      className="text-red-600 hover:text-red-800"
                    >
                      Delete
                    </button>
                  </div>
                  
                  {post.image_url && (
                    <div className="mt-4">
                      <img 
                        src={post.image_url} 
                        alt={post.title}
                        className="w-32 h-32 object-cover rounded"
                      />
                    </div>
                  )}
                  
                  <div className="mt-4 text-gray-700">
                    <div dangerouslySetInnerHTML={{ __html: post.content }} />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManagePosts;