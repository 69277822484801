import React from 'react';

const OurWork = () => (
  <div className="flex flex-col min-h-screen">
    {/* Hero Image Section */}
    <div className="relative h-[60vh] overflow-hidden">
      <img
        src="https://lllqncjwqcbrygqujdin.supabase.co/storage/v1/object/public/media//desk.jpg"
        alt="Kairos technology in action"
        className="w-full h-full object-cover"
      />
    </div>
    {/* Content Section */}
    <div className="container mx-auto px-4 py-16">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-start">
        <h1 className="text-6xl font-bold leading-tight">
          Our Work
        </h1>
        <p className="text-xl leading-relaxed">
          Kairos builds cutting-edge time management and productivity solutions that solve complex organizational challenges for businesses and individuals around the world.
        </p>
      </div>
    </div>
  </div>
);

export default OurWork;