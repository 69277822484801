import React from 'react';
import Header from './Header';
import Footer from './Footer';
import ContactForm from './ContactForm';

const ContactPage = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow flex">
        {/* Image Section */}
        <div className="hidden lg:block lg:w-1/2">
          <img
            className="w-full h-full object-cover"
            src="https://lllqncjwqcbrygqujdin.supabase.co/storage/v1/object/public/media//girl_futuristic.jpg"
            alt="Contact Us"
          />
        </div>
        {/* Contact Form Section */}
        <div className="w-full lg:w-1/2 bg-white overflow-y-auto">
          <div className="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
            <div className="mt-12">
              <ContactForm />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ContactPage;