import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from "./components/HomePage";
import NewsPage from "./components/News";
import ArticleView from "./components/ArticleView";
import StackPage from "./components/StackPage";
import ContactPage from "./components/ContactPage";
import Footer from "./components/Footer";
import TermsAndConditionsPage from "./components/TermsAndConditionsPage";
import PrivacyPolicyPage from "./components/PrivacyPolicyPage";
import AddNewsForm from './components/AddNewsForm';
import ManagePosts from './components/ManagePosts';
import AdminDashboard from './components/AdminDashboard';
import LoginPage, { AuthProvider, ProtectedRoute } from './components/LoginPage';

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <div className="min-h-screen flex flex-col">
          <Routes>
            {/* Public Routes */}
            <Route path="/" element={<HomePage />} />
            <Route path="/news" element={<NewsPage />} />
            <Route path="/news/:id" element={<ArticleView />} />
            <Route path="/stack" element={<StackPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="/terms" element={<TermsAndConditionsPage />} />

            {/* Admin Section */}
            <Route path="/admin">
              {/* Public Admin Routes */}
              <Route path="login" element={<LoginPage />} />
              {/* Protected Admin Routes */}
              <Route index element={
                <ProtectedRoute>
                  <AdminDashboard />
                </ProtectedRoute>
              } />
              <Route path="news">
                <Route path="add" element={
                  <ProtectedRoute>
                    <AddNewsForm />
                  </ProtectedRoute>
                } />
                <Route path="manage" element={
                  <ProtectedRoute>
                    <ManagePosts />
                  </ProtectedRoute>
                } />
              </Route>
            </Route>
          </Routes>
          <Footer />
        </div>
      </AuthProvider>
    </Router>
  );
};

export default App;