import React, { useEffect, useState } from 'react';
import { createClient } from '@supabase/supabase-js';

// Initialize Supabase client with your project's URL and public anon key
const supabase = createClient(
  'https://lllqncjwqcbrygqujdin.supabase.co',
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImxsbHFuY2p3cWNicnlncXVqZGluIiwicm9sZSI6ImFub24iLCJpYXQiOjE3Mzg3MjAxNzMsImV4cCI6MjA1NDI5NjE3M30.Un4XIFBnq1rOrSn484onaCHV0a_mTUYKRb-SVbhKXJQ'
);

const ProductShowcase = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const { data, error } = await supabase
          .from('products')
          .select('*')
          .order('number', { ascending: true }); // Changed to descending order
          
        if (error) throw error;
        if (data) {
          setProducts(data);
        }
      } catch (err) {
        setError(err instanceof Error ? err.message : 'An error occurred');
      } finally {
        setLoading(false);
      }
    };
  
    fetchProducts();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <p className="text-red-500">Error: {error}</p>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      {products.map((product, index) => (
        <div key={product.id} className="mb-8">
          <div className="flex flex-col lg:flex-row items-center justify-center">
            {index % 2 === 0 ? (
              <>
                <div className="lg:w-1/2 flex justify-center">
                  <img 
                    src={product.image_url} 
                    alt={product.alt_text} 
                    className="w-full max-w-xs object-cover rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300"
                  />
                </div>
                <div className="lg:w-1/2 lg:pl-8 mt-6 lg:mt-0 flex flex-col items-center lg:items-start">
                  <span className="text-6xl font-bold text-gray-800">{product.number}</span>
                  <div className="text-center lg:text-left">
                    <p className="text-gray-500 uppercase tracking-wide">{product.category}</p>
                    <h2 className="text-3xl font-bold mb-2 text-gray-900">{product.title}</h2>
                    <p className="text-gray-500">{product.subtitle}</p>
                    <p className="mt-4 text-gray-700 leading-relaxed">{product.description}</p>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="lg:w-1/2 lg:pr-8 mt-6 lg:mt-0 flex flex-col items-center lg:items-end order-2 lg:order-1">
                  <span className="text-6xl font-bold text-gray-800">{product.number}</span>
                  <div className="text-center lg:text-right">
                    <p className="text-gray-500 uppercase tracking-wide">{product.category}</p>
                    <h2 className="text-3xl font-bold mb-2 text-gray-900">{product.title}</h2>
                    <p className="text-gray-500">{product.subtitle}</p>
                    <p className="mt-4 text-gray-700 leading-relaxed">{product.description}</p>
                  </div>
                </div>
                <div className="lg:w-1/2 flex justify-center order-1 lg:order-2">
                  <img 
                    src={product.image_url} 
                    alt={product.alt_text} 
                    className="w-full max-w-xs object-cover rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300"
                  />
                </div>
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProductShowcase;