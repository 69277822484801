import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import { format } from 'date-fns';
import Header from './Header';
import { Link } from 'react-router-dom';


// Initialize Supabase client
const supabase = createClient(
  'https://lllqncjwqcbrygqujdin.supabase.co',
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImxsbHFuY2p3cWNicnlncXVqZGluIiwicm9sZSI6ImFub24iLCJpYXQiOjE3Mzg3MjAxNzMsImV4cCI6MjA1NDI5NjE3M30.Un4XIFBnq1rOrSn484onaCHV0a_mTUYKRb-SVbhKXJQ'
);
const NewsArticle = ({ id, title, content, image_url, category, created_at }) => (
  <Link to={`/news/${id}`} className="block">
    <div className="bg-white rounded-lg shadow-lg overflow-hidden transition-transform hover:scale-[1.02] hover:shadow-xl">
      {image_url && (
        <img src={image_url} alt={title} className="w-full h-48 object-cover" />
      )}
      <div className="p-6">
        <div className="flex items-center justify-between mb-4">
          <p className="text-sm text-gray-500">— {category}</p>
          <p className="text-sm text-gray-500">
            {format(new Date(created_at), 'MMM dd, yyyy')}
          </p>
        </div>
        <h3 className="text-2xl font-bold mb-2">{title}</h3>
        <p className="text-gray-700 mb-4 line-clamp-3">{content}</p>
      </div>
    </div>
  </Link>
);

const NewsPage = () => {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentCategory, setCurrentCategory] = useState('all');
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetchNews();
    fetchCategories();
  }, [currentCategory]);

  const fetchCategories = async () => {
    try {
      const { data, error } = await supabase
        .from('news')
        .select('category')
        .distinct();

      if (error) throw error;

      setCategories(['all', ...data.map(item => item.category)]);
    } catch (err) {
      console.error('Error fetching categories:', err);
    }
  };

  const fetchNews = async () => {
    try {
      let query = supabase
        .from('news')
        .select('*')
        .order('created_at', { ascending: false });

      if (currentCategory !== 'all') {
        query = query.eq('category', currentCategory);
      }

      const { data, error } = await query;

      if (error) throw error;

      setNews(data);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex flex-col">
        <Header />
        <div className="flex-grow flex items-center justify-center">
          <div className="text-xl">Loading news...</div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex flex-col">
        <Header />
        <div className="flex-grow flex items-center justify-center">
          <div className="text-xl text-red-600">Error: {error}</div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <div className="flex-grow bg-gray-50">
        <div className="container mx-auto px-4 py-12">
          <h1 className="text-5xl font-bold mb-8">News</h1>
          <p className="text-xl mb-8">
            Stay updated with our latest news and announcements.
          </p>

          {/* Category Filter */}
          <div className="flex gap-4 mb-8 overflow-x-auto pb-2">
            {categories.map((category) => (
              <button
                key={category}
                onClick={() => setCurrentCategory(category)}
                className={`px-4 py-2 rounded-full ${
                  currentCategory === category
                    ? 'bg-blue-600 text-white'
                    : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                }`}
              >
                {category.charAt(0).toUpperCase() + category.slice(1)}
              </button>
            ))}
          </div>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {news.map((article) => (
              <NewsArticle key={article.id} {...article} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsPage;