import React from 'react';
import { useNavigate } from 'react-router-dom';
import AdminHeader from './AdminHeader';

const AdminDashboard = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen flex flex-col">
      <AdminHeader />
      <div className="flex-grow bg-gray-50 py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold mb-8">Admin Dashboard</h1>
          
          <div className="grid md:grid-cols-2 gap-6">
            <div 
              onClick={() => navigate('/admin/news/add')}
              className="bg-white p-6 rounded-lg shadow hover:shadow-lg cursor-pointer transition-all"
            >
              <h2 className="text-xl font-semibold mb-4">Add New Post</h2>
              <p className="text-gray-600">Create a new news post with rich text content and images.</p>
            </div>
            
            <div 
              onClick={() => navigate('/admin/news/manage')}
              className="bg-white p-6 rounded-lg shadow hover:shadow-lg cursor-pointer transition-all"
            >
              <h2 className="text-xl font-semibold mb-4">Manage Posts</h2>
              <p className="text-gray-600">View, edit, or delete existing news posts.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;